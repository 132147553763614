<template>
  <footer class="footer flex flex-col lg:flex-row bottom-0 w-full px-4 py-6 lg:px-6 lg:py-8 bg-white drop-shadow-[0_-4px_4px_rgba(0,0,0,0.25)]">
    <div class="  flex  flex-col justify-center items-center  ">
      <img class="w-24 -mb-2" src="../assets/nex-door-logo.svg">
      <div class="font-medium leading-tight align-middle text-center font-logo">
        <div class=" font-bold text-lg ">NEX</div>
        <div class=" ">Door</div>
      </div>
    </div>
    <div class="flex flex-col">
      <p class="w-full text-lg leading-snug">
        NEX Door 為非營利服務平台，由美國 NEX Foundation 建置，2020年正式營運。NEX Foundation 為美國註冊商標，屬 NEX Foundation 所有。 其餘各商標均為個別原始公司所有，NEX Foundation 與各該公司並無代理權限或合作關係。
      </p>
      <p class="w-full leading-snug">
        Copyright © 2020
      </p>
      <div class="flex gap-4">
        <a
          href="https://www.instagram.com/nexfoundation/"
          target="_blank"
        ><img src="../assets/instagram-logo.svg"></a>
        <a
          href="https://www.facebook.com/NEXFoundation"
          target="_blank"
        ><img src="../assets/facebook-logo.svg"></a>
      </div>
    </div>
  </footer>
</template>